code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.fontSize-med {
    font-size: 1.2rem;
}
nav.navbar.fixed-top.navbar-expand-lg.navbar-light.bg-light.ps-5.pe-4.w-100 {
    margin: 0;
    padding: 0;
}
.font-size-nav {
    font-size: 1.1rem !important;
}
.font-size-nav-small {
    font-size: 1rem !important;
    font-weight: bold;
}
.navbar-nav .nav-link {
    color: #fff;
    white-space: nowrap;
}

.dropstart .dropdown-toggle {
    margin-right: 1rem;
}
.dropEnd .dropdown-toggle {
    margin-right: 1rem;
}
.dropdown-item:hover {
    color: #000;
}

.dropdown .dropdown-menu {
    display: none;
}

.dropdown:hover > .dropdown-menu, .dropstart:hover > .dropdown-menu {
    display: block;
}
.dropdown:hover > .dropdown-menu, .dropEnd:hover > .dropdown-menu {
    display: block;
}
    .dropEnd:hover > .dropdown-menu {
        position: absolute;
        top: 0;
        left: 100%;
    }

    .dropEnd .dropdown-toggle {
        margin-left: 1rem;
    }
@media screen and (min-width:769px) {
    .dropstart:hover > .dropdown-menu {
        position: absolute;
        top: 0;
        right: 100%;
    }

    .dropstart .dropdown-toggle {
        margin-right: 1rem;
    }
}
.image-comm {
    background-color: transparent !important;
    border: solid 1px transparent;
    width: 25rem;
}
.nav-link {
    font-size: 1.1rem;
}
.img-thumbnail-auto {
    max-height: 15rem !important;
}
.img-thumbnail-comm {
    max-height: 21rem !important;
}
.img-thumbnail-comm1 {
    max-height: 27rem !important;
    width: 100% !important;
}
.img-thumbnail-comm2 {
    max-height: 16rem !important;
    width: 100% !important;
}
.text-dark {
    color: #000 !important;
}
.navbar-brand {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 !important;
    margin-right: var(--bs-navbar-brand-margin-end);
    font-size: var(--bs-navbar-brand-font-size);
    color: var(--bs-navbar-brand-color);
    text-decoration: none;
    white-space: nowrap;
}
.font-size-16 {
    font-size: 1.2rem;
}
.font-size-20 {
    font-size: 1.8rem;
}
.fixed{
    position: absolute;
}
.card-title .card-text {
    opacity: 1
}
.icon-size-logo {
    font-size: 1.5rem;
}
.card {
    background-color: rgba(6,90,138, 0.7);
    z-index: 99;
}
.font-size-text {
    font-size: 1.1rem;
}
.font-size-title {
    font-size: 1.8rem;
}
.image-overly {
    position: relative;
    background-image: linear-gradient(rgba(6,147,227, 0.5), rgba(6,147,227, .5)), url("./gallery/key_machine.jpg");
    background-size: cover;
    background-position: center;
    z-index: -1;
    height: 95px;
}
.carding {
    background-color: #fff !important;
    width: 390px;
    z-index: 99 !important;
}
.card {
    min-height: 25rem;
}
.image-overly1 {
    position: relative;
    background-image: linear-gradient(rgba(6,147,227, 0.5), rgba(6,147,227, .5)), url("./gallery/best_key.webp");
    background-size: cover;
    background-position: center;
    z-index: -1;
    height: 95px;
}
.image-overly2 {
    position: relative;
    background-image: linear-gradient(rgba(6,147,227, 0.5), rgba(6,147,227, .5)), url("./gallery/euro_lock.jpg");
    background-size: cover;
    background-position: center;
    z-index: -1;
    height: 95px;
}
.image-overly3 {
    position: relative;
    background-image: linear-gradient(rgba(6,147,227, 0.5), rgba(6,147,227, .5)), url("./gallery/drill_install.jpg");
    background-size: cover;
    background-position: center;
    z-index: -1;
    height: 95px;
}
.image-overly4 {
    position: relative;
    background-image: linear-gradient(rgba(6,147,227, 0.5), rgba(6,147,227, .5)), url("./gallery/lishi_Volk.jpg");
    background-size: cover;
    background-position: center;
    z-index: -1;
    height: 95px;
}
.image-overly5 {
    position: relative;
    background-image: linear-gradient(rgba(6,147,227, 0.5), rgba(6,147,227, .5)), url("./gallery/KIK_Cylinder.jpg");
    background-size: cover;
    background-position: center;
    z-index: -1;
    height: 95px;
}
.image-overly6 {
    position: relative;
    background-image: linear-gradient(rgba(6,147,227, 0.5), rgba(6,147,227, .5)), url("./gallery/access_mag.jpg");
    background-size: cover;
    background-position: center;
    z-index: -1;
    height: 95px;
}
.image-overly7 {
    position: relative;
    background-image: linear-gradient(rgba(6,147,227, 0.5), rgba(6,147,227, .5)), url("./gallery/key_ring.jpg");
    background-size: cover;
    background-position: 50% 35%;
    z-index: -1;
    height: 95px;
}
.card-img-topping {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: none;
    max-height: none;
    object-fit: cover;
    outline: none;
    border: none;
    box-shadow: none;
    height: 250px;
}
.card-img-top {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: none;
    max-height: none;
    object-fit: cover;
    outline: none;
    border: none;
    box-shadow: none;
    height: 95px;
}
.span_background {
    background-color: #065a8a;
}
.nopadding {
    margin: 0;
    padding: 0;
}
.img-logo {
    height: 9rem;
    margin: 0;
    background-repeat: no-repeat;
    min-width: 100%;
    background-size: cover;
    background-position: center;
    padding: 0;
}
.img-background {
    background-color: transparent;
    border: transparent;
}
.img-logo1 {
    height: 6rem;
    margin: 0;
    background-repeat: no-repeat;
    width: 7rem;
    background-size: cover;
    background-position: center;
    padding: 0;
}
.zIndex1 {
    z-index: 99;
}
.zglenindex {
    z-index: 99;
}
.ResidentialSafeBack {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/Locksmith-working-on-safe.jpg");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 32rem;
}
.ResidentialLockInstallBack {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/door_euro.jpg");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 32rem;
}
.ResidentialKeyExtractBack {
    align-content: center;
    background-position: 50% 60%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/euro_lock.jpg");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 32rem;
}
.ResidentialLockInstall1 {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/best-keying.jpg");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 100%;
}
.CommercialKeyDupBack1 {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/KeyCutting.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 100%;
}
.CommercialInstallationBack1 {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/Alx_Skrew.jpg");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 100%;
}
.CommercialKeyDupBack {
    align-content: center;
    background-position: 50% 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/key-duplication-by-machine.jpg");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 32rem;
}
.CommercialMasterRekeyBack {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/KIK_Cylinder.jpg");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 32rem;
}
.CommercialLockInstallBack {
    align-content: center;
    background-position: 50% 30%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/Locksmith-installing-maglock.JPG");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 32rem;
}
.CommercialPushBarBack {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/Push-bar-locksmith-service.jpg");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 32rem;
}
.CommercialRekeyBack {
    align-content: center;
    background-position: 50% 30%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/Trilogy-electronic-lock.jpg");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 32rem;
}
.HomeLockBack {
    align-content: center;
    background-position: 50% 30%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/Women-locked-out-of-home.jpg");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 32rem;
}
.VehicleLockBack {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/lishi_Volk.jpg");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 32rem;
}
.BusinessLockBack {
    align-content: center;
    background-position: 50% 90%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/Person-Useing-keycard-to-unlock-business-door.jpg");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 32rem;
}
.AutomotiveBack {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/lishi_Volk.jpg");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 30rem;
}
.AutomotiveBack1 {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/Autel-European-Program.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 100%;
}
.CommercialBack {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/access_mag.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 30rem;
}
.ResidentialBack {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/door_euro.jpg");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 33rem;
}
.EmergancyBack {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/Storage-lockout.JPG");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 30rem;
}
.EmergancyBack1 {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/Trailer-Lock.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 100%;
}
.MapletonBack {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/locksmith-services-mapleton-ut.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 30rem;
}
.MapletonBack1 {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/locksmith-Trilogy.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 100%;
}
.OremBack {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/Orem_locksmith_working_on_lock.jpg");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 30rem;
}
.oremBack1 {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/trilogy-Lock.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 100%;
}
.VineyardBack {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/Vineyard_locksmith_working_on_lock.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 30rem;
}
.vineyardBack1 {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/drill-Install.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 100%;
}
.elkRidgeBack {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/Elk_Ridge_locksmith_working_on_lock.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 30rem;
}
.elkRidgeBack1 {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/jeep_cyl.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 100%;
}
.alpineBack {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/Alpine_locksmith_working_on_lock.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 30rem;
}
.alpineBack1 {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/Merc_Program.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 100%;
}
.blogBack {
    align-content: center;
    background-position: 50% 60%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/key_ring.jpg");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 23rem;
}
.lehiBack {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/Lehi_locksmith_working_on_lock.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 30rem;
}
.lehiBack1 {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/best_key.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 100%;
}
.americanForkBack {
    align-content: center;
    background-position: 50% 30%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/American_Fork_locksmith_working_on_lock.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 30rem;
}
.americanForkBack1 {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/door_euro.jpg");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 100%;
}
.lindonBack {
    align-content: center;
    background-position: 50% 30%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/Lindon_locksmith_working_on_lock.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 30rem;
}
.lindonBack1 {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/Key-ALX.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 100%;
}
.eagleMountainBack {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/Eagle_Mountain_locksmith_working_on_lock.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 30rem;
}
.eagleMountainBack1 {
    align-content: center;
    background-position: 50% 10%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/Best-key.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 100%;
}
.provoBack {
    align-content: center;
    background-position: 50% 10%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/Provo_locksmith_working_on_lock.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 30rem;
}
.provoBack1 {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/chevy-key.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 100%;
}
.santaquinBack {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/locksmith-services-santaquin-ut.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 30rem;
}
.salemBack {
    align-content: center;
    background-position: 50% 30%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/locksmith-services-salem-ut.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 30rem;
}
.salemBack1 {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/trilogy-Lock.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 100%;
}
.springvilleBack {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/Genola_locksmith_driving.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 30rem;
}
.springvilleBack1 {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/chevy-key.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 100%;
}
.paysonBack {
    align-content: center;
    background-position: 50% 30%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/locksmith-services-payson-ut.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 35rem;
}
.paysonBack1 {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/drill-Install.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 100%;
}
.spanishForkBack {
    align-content: center;
    background-position: 50% 60%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/locksmith-services-spanish-fork-ut.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 35rem;
}
.spanishForkBack1 {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/access_mag.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 100%;
}
.aboutBack {
    align-content: center;
    background-position: 50% 60%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/UtahCounty-Overlook.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 35rem;
}
.parentBack {
    align-content: center;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/Merc_Program.webp");
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    height: 30rem;
}
.vehicleBack1 {
    color: #fff;
    background-attachment: fixed;
    background-position: 50% 50%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("./gallery/jeep_cyl.webp");
    background-size: cover;
    z-index: -1;
    padding-bottom: 3rem;
    min-height: 100%;
}
.em {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-image: url("./gallery/locksmith-services-springville-ut.jpg");
}
.em3 {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    min-width: 100%;
    height: 100%;
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    background-image: url("./gallery/key_wall.jpg");
}
.em4 {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    min-width: 100%;
    height: 100%;
    background-size: cover;
    z-index: -1;
    background-attachment: fixed;
    background-image: url("./gallery/key_wall.jpg");
}
.em1 {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-image: url("./gallery/door_euro.jpg");
}
.em2 {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-image: url("./gallery/gm_cyl.jpg");
}
.hrBorder {
    border-top-width: 3px !important;
    border-top-style: solid !important;
    color: #18559b !important;
    opacity: 1;
}
.text-car {
    position: absolute;
    width: 100%;
    padding: 0;
    margin: 0;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff !important;
}
.text-vehicle {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #000 !important;
}
.hrWidth {
    width: calc(100% - 431px) !important;
    float: none !important;
    top: 0 !important;
    left: 0 !important;
    position: relative !important;
    height: auto !important;
    z-index: 99;
    max-width: 98px !important;
    margin: 0 auto !important;
    padding: 0 !important;
    min-width: 25px !important;
    text-align: start !important;
    display: block !important;
}
@media (max-width: 992px) {
    .container1 {
        position: absolute;
        z-index: 1;
        bottom: 0;
        background-color: #fff;
        width: 100%;
        height: 60px;
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
    }
}
@media (min-width: 993px) {
    .container1 {
        position: absolute;
        z-index: 1;
        bottom: 0;
        background-color: #fff;
        width: 100%;
        height: 150px;
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
    }
}
    .noTextDecoration {
        text-decoration: none !important;
    }
@media (max-width: 992px) {

    .text-size-big {
        font-size: 1.5rem;
    }
}
@media (min-width: 993px) {

    .text-size-big {
        font-size: 4rem;
    }
}
    html {
        position: relative;
        min-height: 100%;
    }

    .russPic {
        border: 10px solid black !important;
        width: 300px;
        height: 400px;
        margin: 0;
        padding: 0;
    }

    .show {
        display: block;
    }

    .color-main {
        background-color: #f0f1f2;
        padding-bottom: 12rem;
        padding-top: 4rem;
    }

    .mobileleft {
        margin-left: 5rem !important;
        position: fixed !important;
    }

    .borderingLight {
        border: 1px solid #fff !important;
        z-index: 99;
        background: rgba(240, 240, 240, .7);
        color: #000;
    }

    .zIndex {
        z-index: 99;
        position: inherit;
    }
.aboutPictures {
    color: #0d6efd;
    width: 10rem;
    height: 10rem;
    padding: .5rem;
    background: #f8f9fa;
    border-radius: 50%;
}
.aboutIcons {
    color: #0d6efd;
    width: 8rem;
    height: 8rem;
    padding: .5rem;
    background: #f8f9fa;
    border-radius: 50%;
}

    .text {
        position: absolute;
        bottom: 50%;
        margin-top: 4rem;
        color: #fff;
        z-index: 5;
    }

    body {
        margin: 0;
        margin-bottom: 60px;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    @media (max-width : 992px) {
        .padding-mobile {
            margin: 0 !important;
        }
    }

    @media (min-width : 993px) {
        .padding-mobile {
            margin-left: 3rem !important;
            margin-right: 3rem !important;
        }
    }

    @media (max-width: 992px) {
        .space {
            margin-top: 0rem;
        }
    }

    .dropdown {
        z-index: 5;
    }

    @media (max-width: 992px) {
        .MarginStart {
            margin-left: 7rem;
            margin-right: 6rem;
        }
    }

    @media (max-width: 992px) {
        .navbar-collapse {
            position: fixed;
            top: 50px;
            left: 0;
            z-index: 5;
            padding-left: 0px;
            padding-right: 15px;
            padding-bottom: 15px;
            margin-top: 16px;
            font-size: 20px;
            width: 45%;
            height: 100%;
        }

            .navbar-collapse.collapsing {
                left: -75%;
                transition: height 0s;
            }

            .navbar-collapse.show {
                left: 0;
                transition: left 0ms;
            }

        .navbar-toggler.collapsed ~ .navbar-collapse {
            transition: left 0ms;
        }
    }

    @media (max-width: 992px) {
        .navbar-collapse1 {
            position: fixed;
            top: 50px;
            left: 0;
            z-index: 5;
            padding-left: 15px;
            padding-right: 15px;
            padding-bottom: 15px;
            margin-top: 16px;
            width: 100%;
            height: 100px;
        }
    }

    @media (max-width: 992px) {
        .navbar-collapse2 {
            position: fixed;
            top: 50px;
            left: 0;
            z-index: 5;
            padding-left: 35px;
            padding-right: 15px;
            padding-bottom: 15px;
            margin-top: 16px;
            width: 100%;
            height: 155px;
        }
    }

    .background {
        Position: relative;
    }
@media (max-width: 992px) {
    .img-width-sm {
        height: 60px;
    }
}
@media (min-width: 993px) {
    .img-width-sm {
        height: 260px;
        width: 100%;
    }
}

    @media (max-width: 992px) {
        .font-size-18 {
            font-size: .85rem;
        }
    }

    @media (min-width: 993px) {
        .font-size-18 {
            font-size: 1.4rem;
        }
    }

    @media (max-width: 993px) {
        .font-size-45 {
            font-size: 25px !important;
        }
    }

    @media (min-width: 993px) {
        .font-size-45 {
            font-size: 45px !important;
        }
    }

    .carousel-caption {
        position: absolute;
        right: 15%;
        bottom: 50% !important;
        left: 15%;
        z-index: 99;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        color: #fff;
        text-align: center;
    }

    .background:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        background: rgba(0,0,0,0.75);
    }

    @media (max-width: 992px) {
        .carouselheight {
            height: 610px !important;
        }
    }

    @media (min-width: 993px) {
        .carouselheight {
            height: 915px !important;
        }
    }

    @media (max-width: 992px) {
        .img-thumbnail {
            height: 100px !important;
            width: 100%;
        }
    }

    @media (max-width: 992px) {
        .padding-top {
            margin-top: 1rem;
        }
    }

    .font-bold {
        font-weight: bold;
        font-size: 25px;
    }

    .padding-bottom {
        padding-bottom: 10rem;
    }

    button#dropdown-basic-button1 {
        width: 150px;
        border-radius: 1px !important;
    }

    button#dropdown-basic-button2 {
        border-radius: 10px !important;
    }

    button#dropdown-basic-button {
        border-radius: 0px !important;
    }

    .display3 {
        font-size: 3rem;
        font-weight: 900;
        line-height: 1.25;
        margin: 0;
        padding: 0;
    }

    .display2 {
        font-size: 2rem;
        font-weight: 900;
        line-height: 1.25;
        margin: 0;
        padding: 0;
    }

    .today {
        background-color: #54B4D3;
    }

    .text-small {
        font-size: 1.5rem;
        color: #fff;
    }

    footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 60px;
    }

    .footer {
        background-color: #0d6efd;
    }

    footer a {
        color: #fff;
        text-decoration: none !important;
    }

        footer a:hover {
            color: #b1b1b1;
            text-decoration: none !important;
        }

    .map-responsive {
        overflow: hidden;
        padding-bottom: 20px;
    }

    .color-white {
        background-color: #fff;
    }

    .map-responsive iframe {
        left: 0;
        top: 0;
        height: 200px !important;
        width: 400px !important;
    }

    .table > :not(caption) > * > * {
        padding: 0.5rem 0.5rem;
        background-color: var(--bs-table-bg);
        border-bottom-width: 0px !important;
        box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
    }

    .table1 {
        width: 350px !important;
    }

    .fixed {
        position: relative;
        top: 0;
        max-height: 30rem;
    }

    .margintop {
        padding-top: 13rem;
    }

    .width-bar {
        width: 10rem;
    }

    .list-group-item {
        border: none;
    }

    #custom_carousel .item {
        color: #000;
        background-color: #eee;
        padding: 20px 0;
    }

    #custom_carousel .controls {
        overflow-x: auto;
        overflow-y: hidden;
        padding: 0;
        margin: 0;
        white-space: nowrap;
        text-align: center;
        position: relative;
        background: #ddd
    }

        #custom_carousel .controls li {
            display: table-cell;            
        }

            #custom_carousel .controls li.active {
                background-color: #eee;
                border-top: 3px solid orange;
            }

        #custom_carousel .controls a small {
            overflow: hidden;
            display: block;
            font-size: 10px;
            margin-top: 5px;
            font-weight: bold
        }

    .categoryImage {
        height: 20rem;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .categoryCard {
        margin-right: 1rem;
        margin-top: 1rem;
        padding: 0;
        display: flex;
        justify-content: center;
        border: none !important;
    }

    .noSpace {
        --bs-gutter-x: 0;
    }

    .img-thumbnail {
        height: 100% !important;
    }

    .zoomOnHover {
        overflow: hidden;
        margin: 0 auto;
    }

        .zoomOnHover img {
            transition: 0.5s all ease-in-out;
        }

        .zoomOnHover:hover img {
            transform: scale(1.5);
            /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
        }

    .ColFont {
        font-weight: bold;
    }

    .ColIcon {
        font-size: 18px;
    }